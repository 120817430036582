import { IRecommendationFeedBackInterface, IRecommendationInterface } from '@interfaces/recommendation.interface';
import { Model } from './model';

interface IPanier {
    mobile?: string[];
    fai?: string[];
}

export class RecommendationModel extends Model {

    public gencode: string;
    public estRecommande: boolean;
    public produitsAssocies: string[];

    constructor(data?: Partial<IRecommendationInterface>){
        super(data);
    }

}

export class RecommendationFeedBackModel extends Model {

    public clientId: string;
    public commandeId: number;
    public clickEvent: string;
    public panier: IPanier[];

    constructor(data: Partial<IRecommendationFeedBackInterface>){
        super(data);
    }

    public getData(): IRecommendationFeedBackInterface {
        return {
            clientId: this.clientId,
            commandeId: this.commandeId,
            clickEvent: this.clickEvent,
            panier: this.panier,
        };
    }
}
