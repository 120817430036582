import { Component, OnInit } from '@angular/core';
import { MOBILE_RECOVERY_STATUS } from '@interfaces/mobile-recovery-interface';
import { DialogRef } from '@ngneat/dialog';
import { MobileRecoveryService } from '@services/mobile-recovery.service';

export enum RecoverySteps {
    Search, Listing, Result
}

@Component({
    selector: 'tlv-mobile-recovery',
    templateUrl: './mobile-recovery.component.html',
    styleUrls: ['./mobile-recovery.component.scss']
})
export class MobileRecoveryComponent implements OnInit {

    public recoverySteps = RecoverySteps;
    public currentStep = this.recoverySteps.Search;

    constructor(
        public ref: DialogRef,
        private mobileRecoveryService: MobileRecoveryService,
    ) { }

    public ngOnInit(): void {
        this.currentStep = this._getLastStepToComplete();
    }

    public nextStep(): void {
        this.currentStep++;
    }

    public previousStep(): void {
        this.currentStep--;
    }

    public goToSearchStep(): void {
        this.currentStep = this.recoverySteps.Search;
    }

    private _getLastStepToComplete(): RecoverySteps {
        let lastStep = this.recoverySteps.Search;
        const currentMobileRecovery = this.mobileRecoveryService.currentMobileRecovery;

        if (currentMobileRecovery?.id && [MOBILE_RECOVERY_STATUS.ONGOING, MOBILE_RECOVERY_STATUS.UNFINISHED]
            .includes(currentMobileRecovery.partnerData.status)) {
            lastStep = this.recoverySteps.Listing;

            if (currentMobileRecovery.partnerData.status === MOBILE_RECOVERY_STATUS.COMPLETED) {
                lastStep = this.recoverySteps.Result;
            }
        }
        return lastStep;
    }

}
