<div class="columns is-marginless" data-cy="mobile-recovery-listing">
    <div class="column has-text-left">
        <p class="title is-spaced is-size-3 main-title">Reprise mobile</p>
        <p class="subtitle is-size-6">
            Indiquez l'état de votre <span class="has-text-weight-semibold">{{selectedMobileReco.name}}.</span>
        </p>

        <div *ngIf="!features?.length">
            <tlv-loader isAnimated="true" loadingText="Chargement des questions..."></tlv-loader>
        </div>
        <div class="question-list">
            <div [data-cy]="'mobile-recovery-question-'+index" class="question-item" *ngFor="let feature of features; last as isLast; index as index;">
                <div class="columns is-fullwidth">
                    <div class="column column-question has-text-left is-flex is-vcentered">
                        <p class="text is-1 has-text-weight-bold is-flex is-marginless is-inline">
                            <span>{{ feature.label }}</span>
                        </p>
                        <div tabindex="0" class="popover recovery-trigerred-popover is-popover-left">
                            <span tabindex="0" class="icon is-small icon-info is-inline-flex is-justified-center popover-trigger"><i class="tri-infos-circle"></i></span>
                            <div class="popover-content">
                                <ng-container *ngFor="let description of feature | filterFeaturesDescription : selectedMobileReco.partnerData.surveys">
                                    <p class="popover-content-text text is-3 has-text-white" [innerHTML]="description"></p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <div class="options">
                            <ng-container *ngFor="let option of feature.options; index as idx">
                                <div class="field">
                                    <div class="control">
                                        <input type="radio" [id]="'option-'+feature.id+''+idx" [attr.name]="feature.id" [checked]="getSelectedOption(feature, option)" [value]="option" (change)="selectFeature($event, feature)">
                                        <label [data-cy]="'mobile-recovery-question-'+index+'-choice-'+option" [for]="'option-'+feature.id+''+idx">{{option}}</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'has-background-white': isLast, 'is-marginless': !isLast}" class="is-divider"></div>
            </div>
        </div>

        <div class="columns is-marginless is-justified-between">
            <div class="column is-paddingless is-narrow">
                <span class="icon-and-text">
                    <span class="icon is-small">
                        <i class="tri-arrow-left"></i>
                    </span>
                    <a class="has-text-weight-bold has-text-tertiary paragraph is-1 text" (click)="previous()">Retour</a>
                </span>
            </div>
            <div class="column is-paddingless is-narrow">
                <button data-cy="mobile-recovery-listing-validate" class="is-primary button" (click)="next()" [disabled]="formIncomplete" [class.is-loading]="isLoading">
                    Continuer la reprise
                </button>
            </div>
        </div>
        <div class="column is-paddingless">
            <div class="is-divider has-background-white"></div>
        </div>
    </div>

</div>
