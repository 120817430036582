<div class="columns is-marginless">
    <div class="column is-12">
        <div class="box is-flat is-borderless">
            <div class="box-content">
                <div class="recovery-modal-wrapper inner-modal-content">
                    <tlv-mobile-recovery-search *ngIf="currentStep === recoverySteps.Search" (goToNextStep)="nextStep()"></tlv-mobile-recovery-search>
                    <tlv-mobile-recovery-listing *ngIf="currentStep === recoverySteps.Listing" (goToNextStep)="nextStep()" (goToPreviousStep)="previousStep()"></tlv-mobile-recovery-listing>
                    <tlv-mobile-recovery-result *ngIf="currentStep === recoverySteps.Result" (goToNextStep)="nextStep()" (goToPreviousStep)="previousStep()" (goToSearchStep)="goToSearchStep()"></tlv-mobile-recovery-result>
                </div>        
            </div>
        </div>
    </div>
</div>