import { Injectable } from '@angular/core';
import { RecommendationModel, RecommendationFeedBackModel } from '@models/recommendation';
import { RecommendationRepository } from '@repositories/recommendation.repository';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RecommendationService {

    protected _recommendations: RecommendationModel[] = [];

    constructor(
        private recommendationRepository: RecommendationRepository,
    ) { }

    public getRecommendations(): RecommendationModel[] {
        return this._recommendations;
    }

    public resolve(): Observable<RecommendationModel[]> {
        return this.recommendationRepository.getRecommendations().pipe(
            tap((recommendations: RecommendationModel[]) => {
                if (recommendations) {
                    this._recommendations = recommendations;
                }
                return this._recommendations;
            }),
            catchError( () => of([]))
        );
    }

    public sendFeedBack(recommendationFeedBack: RecommendationFeedBackModel): Observable<void> {
        return this.recommendationRepository.sendFeedbackInteraction(recommendationFeedBack);
    }

    public isRecommended(gencode: string, gencode2: string): boolean {
        return !!this._recommendations.find(
            (recommendation: RecommendationModel) =>
                (recommendation?.gencode === gencode && recommendation?.produitsAssocies?.includes(gencode2))
        );
    }
}
