import { Injectable } from '@angular/core';
import { Equipment, Plan, Edp, IEdp, CartModel } from '@bytel/bytel-sales';
import { ScoringService } from './scoring.service';

@Injectable({
    providedIn: 'root'
})
export class EdpService {

    public subscription: Plan;
    public equipment: Equipment;

    constructor(
        private scoringService: ScoringService
    ) {
    }

    public isEdpAllowed(cartModel: CartModel): boolean {
        if (!cartModel.getQuote()){return true;}
        return cartModel.promotions.canHaveEdp() && new Edp({} as IEdp).canAdd(cartModel.getQuote());
    }

}
