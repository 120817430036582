<div data-cy="mobile-recovery-result" *ngIf="selectedMobileReco.prices.final >= 10 else minRecovery" class="has-text-left main-content">
    <p class="title is-spaced is-size-3 main-title">Reprise mobile</p>
    <p class="subtitle is-size-6">
        Finalisez la reprise de votre <span class="has-text-weight-semibold">{{selectedMobileReco.name}}</span>
        <span class="text paragraph is-3 has-text-weight-normal">
                    (estimation valide jusqu’à 23h59 ce soir).
                </span>
    </p>
    <div class="columns is-centered is-vcentered is-marginless">
        <div data-cy="mobile-recovery-result-estimation" class="column is-narrow" [ngClass]="{'box has-background-sky-blue is-flat is-borderless': !choiceInsurance && !selectedMobileReco?.partnerData?.bonus}">
            <p class="text paragraph is-1 has-text-weight-normal is-self-centered">Valeur estimée<br> de votre téléphone</p>
            <div class="column is-12">
                <div class="price is-size-1 is-self-centered">
                    <span class="main">{{selectedMobileReco.prices.final}}</span>
                    <span class="price-details"><span class="cents">€</span></span>
                </div>
            </div>
        </div>
        <!-- Avec garantie assurance -->
        <div class="column is-narrow" *ngIf="choiceInsurance">
            <span class="icon is-small"><i class="tri-minus-circle"></i></span>
        </div>
        <div data-cy="mobile-recovery-result-insurance" class="column is-narrow" *ngIf="choiceInsurance">
            <p class="text paragraph is-1 has-text-weight-normal is-self-centered">Garantie transport</p>
            <div class="column is-12">
                <div class="price is-size-1 is-self-centered">
                    <span class="main">{{selectedMobileReco.partnerData.insuranceAmount}}</span>
                    <span class="price-details"><span class="cents">€</span></span>
                </div>
            </div>
        </div>

        <!-- Bonus -->
        <div class="column is-narrow" *ngIf="selectedMobileReco?.partnerData?.bonus">
            <span class="icon is-small"><i class="tri-plus-circle"></i></span>
        </div>
        <div data-cy="mobile-recovery-result-bonus" class="column is-narrow" *ngIf="selectedMobileReco?.partnerData?.bonus">
            <p class="text paragraph is-1 has-text-weight-normal is-self-centered">Bonus reprise</p>
            <div class="column is-12">
                <div class="price is-size-1 is-self-centered">
                    <span class="main">{{selectedMobileReco.partnerData.bonus}}</span>
                    <span class="price-details"><span class="cents">€</span></span>
                </div>
            </div>
        </div>

        <div *ngIf="choiceInsurance || selectedMobileReco?.partnerData?.bonus" class="icon-operation">
            <span class="icon is-small icon-equal"><i class="tri-pause"></i></span>
        </div>

        <div data-cy="mobile-recovery-result-total-amount"  class="column is-narrow box has-background-sky-blue is-flat is-borderless" *ngIf="choiceInsurance || selectedMobileReco?.partnerData?.bonus">
            <p class="text paragraph is-1 has-text-weight-bold is-self-centered">Total</p>
            <div class="column is-12">
                <div class="price is-size-1 is-self-centered">
                    <span class="main">{{totalAmount}}</span>
                    <span class="price-details"><span class="cents">€</span></span>
                </div>
            </div>
        </div>
    </div>

    <hr class="is-divider">

    <div class="question-item">
        <div class="columns is-fullwidth">
            <div class="column is-8 column-question has-text-left">
                <p class="has-text-weight-bold text is-1">
                    Souhaitez-vous garantir votre envoi ({{5}}€) ?
                </p>
                <p class="label-warranty text is-1">
                    100% de la valeur estimée de votre ancien téléphone <span *ngIf="selectedMobileReco?.partnerData?.bonus">+ bonus reprise</span> remboursé<span *ngIf="selectedMobileReco?.partnerData?.bonus">s</span> en cas de perte ou de vol pendant le transport sous réserve d’apporter la preuve de dépôt à un bureau de poste.
                    Sans garantie transport, remboursement limité <span *ngIf="selectedMobileReco?.partnerData?.bonus">au montant du bonus reprise +</span><span *ngIf="!selectedMobileReco?.partnerData?.bonus">à</span> 60% du montant de l’estimation.
                </p>
            </div>
            <div class="column is-4 is-narrow">
                <div class="options">
                    <ng-container *ngFor="let option of ['Oui','Non']; index as idx">
                        <div class="field">
                            <div class="control">
                                <input type="radio" [id]="'option-'+idx" name="warranty" [value]="option" (change)="selectInsurance($event)">
                                <label [data-cy]="'mobile-recovery-result-bonus-choice-'+option" [for]="'option-'+idx">{{option}}</label>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="is-divider"></div>
    </div>

    <div class="field is-flex is-justified-end-desktop recovery-legals">
        <input data-cy="mobile-recovery-accept-cgv" class="is-checkradio has-background-color is-info" id="checkCondition" type="checkbox" [checked]="checkCondition" (change)="checkCondition = !checkCondition">
        <label class="is-marginless text is-2 paragraph has-text-weight-normal" for="checkCondition">
            <span>J’accepte les&nbsp;<a class="link" href="https://www.recyclage-mobiles.bouyguestelecom.fr/Conditions-Generales-du-Service" target="_blank" rel="noopener noreferrer">Conditions Générales de Reprise</a></span>
        </label>
    </div>

    <div class="columns is-marginless is-justified-between">
        <div class="column is-paddingless is-narrow">
            <span class="icon-and-text">
                <span class="icon is-small">
                    <i class="tri-arrow-left"></i>
                </span>
                <a class="has-text-weight-bold has-text-tertiary paragraph is-1 text" (click)="previous()">Retour</a>
            </span>
        </div>
        <div class="column is-paddingless is-narrow">
            <button data-cy="mobile-recovery-final-validation"  [disabled]="!(checkCondition && hasClickInsurance)" class="button is-primary" (click)="close()">
                Confirmer<span class="hide-on-xs">&nbsp;votre reprise</span>
            </button>
        </div>
    </div>
    <div class="is-divider has-background-white"></div>
</div>

<ng-template #minRecovery>
    <div class="has-text-left inner-modal-content main-content" *ngIf="!isLoading">
        <div class="row">

            <p class="title is-size-3 main-title">Reprise mobile</p>

            <div class="notification is-small has-body">
                <div class="icon">
                    <i class="tri-infos-circle"></i>
                </div>
                <div class="body">
                    <h5 class="text paragraph is-1 has-text-weight-bold">Malheureusement, nous ne pouvons pas reprendre votre mobile en ligne car sa valeur estimée
                        est inférieure à {{10}}€.</h5>
                </div>
            </div>
            <p class="text is-1">Cependant, nous pouvons le recycler gratuitement en boutique.</p>
            <p class="text is-1">Vous pouvez essayer de <a (click)="goToSearch()" class="link">faire estimer un autre mobile</a>
                <span *ngIf="selectedMobileReco?.partnerData?.bonus">&nbsp;pour bénéficier du bonus reprise </span>
                ou finaliser votre achat.</p>
            <div class="is-divider has-background-white"></div>
            <div class="columns is-marginless is-justified-between">
                <div class="column is-paddingless is-narrow">
                    <span class="icon-and-text">
                        <span class="icon is-small">
                            <i class="tri-arrow-left"></i>
                        </span>
                        <a class="has-text-weight-bold has-text-tertiary paragraph is-1 text" (click)="goToSearch()">Retour</a>
                    </span>
                </div>
                <div class="column is-paddingless is-narrow">
                    <button class="button is-primary" (click)="close(true)">Finaliser votre achat</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
