import { Injectable } from '@angular/core';
import { ConfigurationService, Oauth2ResourcesService } from '@common-modules';
import { IRecommendationInterface } from '@interfaces/recommendation.interface';
import { RecommendationModel, RecommendationFeedBackModel } from '@models/recommendation';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class RecommendationRepository {

    constructor(private oauth2Resource: Oauth2ResourcesService,
                private configService: ConfigurationService) {}

    public getRecommendations(): Observable<RecommendationModel[]> {
        if (this.configService.data_refconf?.mobile?.recommendations_active !== true) {
            return of([new RecommendationModel()]);
        }

        return this.oauth2Resource
            .useSalesApi()
            .recommendations()
            .get<IRecommendationInterface[]>()
            .pipe(
                map((data) => data.map((recommendation) => new RecommendationModel(recommendation)))
            );
    }

    public sendFeedbackInteraction(recommendationFeedBack: RecommendationFeedBackModel): Observable<void> {
        if (this.configService.data_refconf?.mobile?.recommendations_active !== true) {
            return of();
        }

        return this.oauth2Resource
            .useSalesApi()
            .recommendations()
            .feedbackInteraction()
            .put(recommendationFeedBack.getData());
    }
}
