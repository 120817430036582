<div class="has-text-left" data-cy="mobile-recovery-search">
    <p class="title is-spaced is-size-3 main-title">Reprise mobile</p>
    <p class="subtitle is-size-6">Estimez votre ancien téléphone au meilleur prix<span *ngIf="selectedMobileReco?.partnerData?.bonus > 0">&#32;et bénéficiez d’un bonus reprise</span>.</p>
    <div class="search-form">
        <div class="notification is-small has-body is-warning" *ngIf="!!selectedMobileReco.name && !selectedMobileReco?.id" [tlvPrismeLogger]="'Reprise mobile id null'">
            <div class="icon" aria-label="Attention">
                <i class="tri-exclamation-circle" aria-hidden="true"></i>
            </div>
            <div class="body">
                <p class="title">
                    Une erreur technique nous empêche de récupérer les informations concernant la reprise mobile du {{selectedMobileReco.name}} auprès de notre partenaire.
                </p>
            </div>
        </div>
        <div class="columns is-vcentered">
            <div class="column">
                <div class="field dropdown" [ngClass]="{'is-active': filtredProducts.length>0}">
                    <div class="control has-icons-right">
                        <input
                        data-cy="mobile-recovery-search-input"
                        class="input search-input"
                        type="text"
                        placeholder="Marque et modèle de votre ancien téléphone"
                        [(ngModel)]="searchText"
                        (ngModelChange)="changeSearchText($event)">
                        <span class="icon is-small has-text-tertiary">
                            <i [ngClass]="!searchText ? 'tri-search' : 'tri-times-circle'" class="has-curs-pointer" (click)="clearSearch()"></i>
                        </span>
                        <div class="dropdown-menu">
                            <div class="dropdown-content" data-cy="mobile-recovery-founded-products">
                                <div class="dropdown-item has-cursor" (click)="selectedItem(product)" *ngFor="let product of filtredProducts">
                                    <span [data-cy]="'mobile-recovery-founded-product-'+product.id">{{ product.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-narrow">
                <button data-cy="mobile-recovery-search-start" class="button is-primary" [disabled]="!selectedMobileReco?.name || !selectedMobileReco?.id" (click)="next()">Estimer</button>
            </div>
        </div>
    </div>
    <div class="has-background-grey-lighter is-borderless box is-flat info-block">
        <ng-container *ngTemplateOutlet="infoTitle"></ng-container>
        <ng-container *ngTemplateOutlet="info1"></ng-container>
        <ng-container *ngTemplateOutlet="info2"></ng-container>
        <ng-container *ngTemplateOutlet="info3"></ng-container>
        <ng-container *ngTemplateOutlet="info4"></ng-container>
    </div>
    <div class="text is-2 paragraph has-text-weight-normal">
        <span class="has-text-weight-semibold">Des questions ?</span> Retrouvez les réponses de nos experts Bouygues Telecom sur
        &#32;<a class="link" rel="noopener noreferrer" href="https://www.recyclage-mobiles.bouyguestelecom.fr/Questions-Reponses" target="_blank">notre FAQ</a>
    </div>
</div>

<ng-template #infoTitle>
    <p class="title is-level-3 is-marginless">Comment ça marche ?</p>
</ng-template>
<ng-template #info1>
    <p class="has-text-weight-semibold info-subtitle text is-1">1. Estimez la valeur de votre ancien mobile</p>
    <p *ngIf="selectedMobileReco?.partnerData?.bonus > 0" class="text is-1 is-marginless">
        et bénéficiez en plus d’un bonus reprise de
        <span class="has-text-info has-text-weight-semibold">{{ selectedMobileReco?.partnerData?.bonus }}€</span>
        &#32;pour l’achat d’un {{ ref.data?.name }} et la souscription à un forfait Sensation, engagement 24 mois <i>(hors séries spéciales)</i>.
    </p>
</ng-template>
<ng-template #info2>
    <p class="has-text-weight-semibold info-subtitle text is-1">2. Finalisez l'achat de votre nouveau téléphone</p>
</ng-template>
<ng-template #info3>
    <p class="has-text-weight-semibold info-subtitle text is-1">3. Envoyez votre ancien mobile gratuitement</p>
    <p class="text is-1 is-marginless">
        a) Recevez à votre adresse de facturation (merci de la vérifier avant de valider votre commande) une enveloppe pré-affranchie et pré-adressée à notre partenaire&nbsp;&thinsp;<img class="recommerce-logo" src="assets/svg/logo-recommerce.svg" alt="recommerce">
    </p>
    <p class="text is-1 is-marginless">b) Joignez à votre envoi le bon de cession, reçu par email après validation de votre reprise.</p>
</ng-template>
<ng-template #info4>
    <p class="has-text-weight-semibold info-subtitle text is-1">4. Recevez le paiement de votre ancien mobile sous 48h</p>
    <p class="text is-1 is-marginless">par virement bancaire après contrôle en atelier par notre partenaire&nbsp;&thinsp;<img class="recommerce-logo" src="assets/svg/logo-recommerce.svg" alt="recommerce"></p>
    <p class="text is-1 is-marginless" *ngIf="selectedMobileReco?.partnerData?.bonus > 0">Le bonus reprise est lui déduit de votre 2<sup>ème</sup> ou 3<sup>ème</sup> facture Bouygues Telecom, si votre mobile repris est validé à {{10}}€ minimum.</p>
</ng-template>
<div class="is-divider has-background-white"></div>
<!-- <ng-template #typeaheadResultTemplate let-phone="result" let-term="search">
    {{ phone.nom }}
</ng-template> -->
